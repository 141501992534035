.app {
  min-height: var(--min-height);
  justify-content: center;
  min-width: 1200px;
  height: 100%;
  display: flex;
  position: relative;
}

.app:before {
  content: "";
  background: var(--surface-body-darkbg, #0e1a33);
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
}

@media (width <= 1024px) {
  .app:before {
    width: 100%;
  }
}

.app:after {
  content: "";
  background: url("preview.e6d0d281.svg") top / cover no-repeat;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
}

@media (width <= 1024px) {
  .app:after {
    display: none;
  }

  .app {
    min-width: initial;
  }
}

.app-nav {
  padding: var(--spacing-spacing-24, 24px) var(--spacing-spacing-48, 48px);
  box-sizing: border-box;
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  display: flex;
  position: absolute;
}

.app-nav button {
  padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-spacing-4, 4px);
  border-radius: var(--radius-radius-4, 4px);
  background: var(--brand-500, #ff4240);
  cursor: pointer;
  display: flex;
  box-shadow: inset 0 1.5px #fff3, inset 0 -1.5px #0000001a;
}

.app-nav button span {
  padding: 0px var(--spacing-spacing-8, 8px);
  color: var(--neutral-100, #fff);
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  font-family: Instrument Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
}

.app-nav button img {
  width: var(--spacing-spacing-16, 16px);
  height: var(--spacing-spacing-16, 16px);
}

@media (width <= 1024px) {
  .app-nav button {
    display: none;
  }
}

.app-container {
  z-index: 1;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  min-height: 980px;
  display: flex;
}

.app-container .app-inputs {
  padding: 0px var(--spacing-spacing-96, 96px) var(--spacing-spacing-64, 64px) var(--spacing-spacing-128, 128px);
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-spacing-48, 48px);
  z-index: 2;
  box-sizing: border-box;
  flex-direction: column;
  flex: 50%;
  margin-top: 110px;
  display: flex;
}

.app-container .app-inputs > div {
  align-items: flex-start;
  gap: var(--spacing-spacing-20, 20px);
  flex-direction: column;
  display: flex;
}

.app-container .app-inputs .app-inputs-upload {
  align-self: stretch;
}

.app-container .app-inputs .app-inputs-upload #upload-button {
  display: none;
}

.app-container .app-inputs .app-inputs-upload #upload-container {
  padding: var(--spacing-spacing-20, 20px) var(--spacing-spacing-16, 16px);
  box-sizing: border-box;
  border-radius: var(--radius-radius-4, 4px);
  background: var(--primary-700, #27334d);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  height: 56px;
  display: flex;
}

.app-container .app-inputs .app-inputs-upload #upload-container span {
  color: var(--text-primary-darkbg, #f8f9ff);
  text-align: center;
  font-family: Instrument Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.app-container .app-inputs .app-inputs-upload #upload-container:hover {
  background: var(--primary-600, #3f4c68);
}

.app-container .app-inputs .app-inputs-upload #uploaded-img {
  padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
  box-sizing: border-box;
  border-radius: var(--radius-radius-4, 4px);
  background: var(--primary-700, #27334d);
  align-items: center;
  gap: 10px;
  width: 80%;
  height: 56px;
  display: none;
}

.app-container .app-inputs .app-inputs-upload #uploaded-img > img {
  width: 32px;
  height: 32px;
}

.app-container .app-inputs .app-inputs-upload #uploaded-img .app-inputs-upload-filename {
  color: var(--text-primary-darkbg, #f8f9ff);
  text-align: left;
  flex: 1;
  font-family: Instrument Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.app-container .app-inputs .app-inputs-upload #upload-error {
  padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
  box-sizing: border-box;
  border-radius: var(--radius-radius-4, 4px);
  border: 1px solid var(--utility-red-500, #db2c2c);
  background: #f3e1e1;
  align-items: center;
  gap: 10px;
  width: 80%;
  height: 56px;
  display: none;
}

.app-container .app-inputs .app-inputs-upload #upload-error div {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.app-container .app-inputs .app-inputs-upload #upload-error .app-inputs-upload-filename {
  color: var(--utility-red-500, #db2c2c);
  text-align: center;
  font-family: Instrument Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.app-container .app-inputs .app-inputs-upload #upload-error .app-inputs-upload-error {
  color: var(--utility-red-500, #db2c2c);
  text-align: center;
  font-family: Instrument Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.app-container .app-inputs .app-inputs-backgrounds-title {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.app-container .app-inputs .app-inputs-stickers-input li img {
  max-width: 64px;
  max-height: 64px;
}

.app-container .app-preview {
  z-index: 1;
  box-sizing: border-box;
  flex: 50%;
  min-height: 980px;
  padding: 110px 32px 0;
}

.app-container .app-preview .app-preview-container {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  min-height: 870px;
  display: flex;
  position: relative;
}

.app-container .app-preview .app-preview-container:after {
  content: " ";
  pointer-events: none;
  border: 1px solid #a1b1d2;
  border-radius: 50%;
  width: 256px;
  height: 256px;
  position: absolute;
  box-shadow: 0 0 0 99999px #0a132680;
}

.app-container .app-preview .app-preview-avatar {
  border-radius: 50%;
  width: 256px;
  height: 256px;
  position: absolute;
}

.app-container .app-preview .app-preview-avatar #avatar-background-container, .app-container .app-preview .app-preview-avatar #avatar-frame-container {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.app-container .app-preview .app-preview-avatar #avatar-background-container img, .app-container .app-preview .app-preview-avatar #avatar-frame-container img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  left: -10px;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container {
  padding: 10px 14px 10px 10px;
  position: absolute;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container img {
  width: 100%;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container .app-close-button {
  display: none;
  position: absolute;
  top: 0;
  right: -6px;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container .app-sticker-border {
  width: calc(100% - 22px);
  height: calc(100% - 16px);
  margin: 8px 14px 8px 8px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container[data-type="sticker"] {
  width: 80px;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container[data-type="upload"] {
  width: 256px;
}

.app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container:hover .app-sticker-border, .app-container .app-preview .app-preview-avatar #avatar-sticker-container .image-container:hover:not([data-type="upload"]) .app-close-button {
  display: block;
}

@media (width <= 1024px) {
  .app-container {
    display: none;
  }
}

.mobile-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px;
  display: none;
  position: absolute;
}

@media (width <= 1024px) {
  .mobile-container {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: flex;
  }
}

.app-close-button {
  border-radius: var(--radius-radius-pill, 999px);
  background: var(--utility-red-500, #db2c2c);
  padding: var(--spacing-spacing-4, 4px);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 18px;
  height: 18px;
  display: flex;
}

.app-sticker-border .app-sticker-border-line-top, .app-sticker-border .app-sticker-border-line-bottom, .app-sticker-border .app-sticker-border-line-left, .app-sticker-border .app-sticker-border-line-right {
  background: var(--divider-light, #dae1f5);
  width: 1px;
  height: 1px;
  position: absolute;
}

.app-sticker-border .app-sticker-corner-tl, .app-sticker-border .app-sticker-corner-tr, .app-sticker-border .app-sticker-corner-bl, .app-sticker-border .app-sticker-corner-br {
  width: var(--spacing-spacing-4, 4px);
  height: var(--spacing-spacing-4, 4px);
  border: 1px solid var(--divider-light, #dae1f5);
  background: var(--neutral-100, #fff);
  position: absolute;
}

.app-sticker-border .app-sticker-border-line-top {
  width: 100%;
  top: 2px;
}

.app-sticker-border .app-sticker-border-line-bottom {
  width: 100%;
  bottom: 2px;
}

.app-sticker-border .app-sticker-border-line-left {
  height: 100%;
  left: 2px;
}

.app-sticker-border .app-sticker-border-line-right {
  height: 100%;
  right: 2px;
}

.app-sticker-border .app-sticker-corner-tl {
  top: 0;
  left: 0;
}

.app-sticker-border .app-sticker-corner-tr {
  top: 0;
  right: 0;
}

.app-sticker-border .app-sticker-corner-bl {
  bottom: 0;
  left: 0;
}

.app-sticker-border .app-sticker-corner-br {
  bottom: 0;
  right: 0;
}

.app-sticker-border .app-sticker-rotate-handle-arm {
  background: var(--divider-light, #dae1f5);
  width: 2px;
  height: 12px;
  position: absolute;
  top: -10px;
  left: 50%;
  right: 50%;
}

.app-sticker-border .app-sticker-rotate-handle {
  border: 1px solid var(--divider-light, #dae1f5);
  background: var(--neutral-100, #fff);
  cursor: grabbing;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: -12px;
  left: calc(50% - 3px);
  right: calc(50% - 3px);
}

html {
  --min-height: max(100%, 980px);
  height: 100%;
}

body {
  min-height: var(--min-height);
  margin: 0;
  padding: 0;
  position: relative;
}

@media (pointer: coarse) {
  body {
    min-height: 100dvh;
  }
}

button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

h3 {
  color: var(--text-primary-darkbg, #f8f9ff);
  margin: 0;
  font-family: Instrument Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h6 {
  color: var(--text-supporting-darkbg, #8998b7);
  margin: 0;
  font-family: Instrument Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

a {
  color: var(--text-primary-darkbg, #f8f9ff);
  text-decoration-line: underline;
}

ul {
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

ul li {
  border-radius: var(--radius-radius-8, 8px);
  border: 1px solid var(--primary-600, #3f4c68);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  display: flex;
}

ul li img {
  border-radius: var(--radius-radius-7, 7px);
  max-width: 100%;
  max-height: 100%;
}
/*# sourceMappingURL=index.d6846c07.css.map */
