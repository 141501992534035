.app {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 1200px;
  min-height: var(--min-height);
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background: var(--surface-body-darkbg, #0e1a33);

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    background: url(../images/preview.svg) top/cover no-repeat;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    min-width: initial;
  }
}

.app-nav {
  position: absolute;
  max-width: 1600px;
  width: 100%;
  display: flex;
  padding: var(--spacing-spacing-24, 24px) var(--spacing-spacing-48, 48px);
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;

  button {
    display: flex;
    padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-spacing-4, 4px);
    border-radius: var(--radius-radius-4, 4px);
    background: var(--brand-500, #ff4240);
    cursor: pointer;

    /* .button/shadingDefault */
    box-shadow: 0px 1.5px 0px 0px rgba(255, 255, 255, 0.2) inset, 0px -1.5px 0px 0px rgba(0, 0, 0, 0.1) inset;

    span {
      display: flex;
      padding: 0px var(--spacing-spacing-8, 8px);
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      color: var(--neutral-100, #fff);

      /* Button/btn-lg */
      font-family: 'Instrument Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 100% */
    }

    img {
      width: var(--spacing-spacing-16, 16px);
      height: var(--spacing-spacing-16, 16px);
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.app-container {
  max-width: 1600px;
  width: 100%;
  height: 100%;
  min-height: 980px;
  display: flex;
  z-index: 1;

  .app-inputs {
    padding: 0px var(--spacing-spacing-96, 96px) var(--spacing-spacing-64, 64px) var(--spacing-spacing-128, 128px);
    margin-top: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-spacing-48, 48px);
    flex: 1 50%;
    z-index: 2;
    box-sizing: border-box;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-spacing-20, 20px);
    }

    .app-inputs-upload {
      align-self: stretch;

      #upload-button {
        display: none;
      }

      #upload-container {
        display: flex;
        width: 80%;
        height: 56px;
        padding: var(--spacing-spacing-20, 20px) var(--spacing-spacing-16, 16px);
        box-sizing: border-box;
        border-radius: var(--radius-radius-4, 4px);
        background: var(--primary-700, #27334d);
        align-items: center;
        gap: 10px;
        justify-content: center;
        cursor: pointer;

        span {
          color: var(--text-primary-darkbg, #f8f9ff);
          text-align: center;
          font-family: 'Instrument Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
        }

        &:hover {
          background: var(--primary-600, #3f4c68);
        }
      }

      #uploaded-img {
        display: flex;
        width: 80%;
        height: 56px;
        padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
        box-sizing: border-box;
        border-radius: var(--radius-radius-4, 4px);
        background: var(--primary-700, #27334d);
        align-items: center;
        gap: 10px;
        display: none;

        > img {
          width: 32px;
          height: 32px;
        }

        .app-inputs-upload-filename {
          flex: 1;
          color: var(--text-primary-darkbg, #f8f9ff);
          text-align: left;
          font-family: 'Instrument Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
        }
      }

      #upload-error {
        display: flex;
        width: 80%;
        height: 56px;
        padding: var(--spacing-spacing-12, 12px) var(--spacing-spacing-16, 16px);
        box-sizing: border-box;
        border-radius: var(--radius-radius-4, 4px);
        border: 1px solid var(--utility-red-500, #db2c2c);
        background: #f3e1e1;
        align-items: center;
        gap: 10px;
        display: none;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
          gap: 2px;
        }

        .app-inputs-upload-filename {
          color: var(--utility-red-500, #db2c2c);
          text-align: center;
          font-family: 'Instrument Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
        }

        .app-inputs-upload-error {
          color: var(--utility-red-500, #db2c2c);
          text-align: center;
          font-family: 'Instrument Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
      }
    }

    .app-inputs-backgrounds-title {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .app-inputs-stickers-input {
      li {
        img {
          max-width: 64px;
          max-height: 64px;
        }
      }
    }
  }

  .app-preview {
    padding: 110px 32px 0px 32px;
    flex: 1 50%;
    z-index: 1;
    box-sizing: border-box;
    min-height: 980px;

    .app-preview-container {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 870px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      &::after {
        content: ' ';
        position: absolute;
        width: 256px;
        height: 256px;
        border-radius: 50%;
        border: 1px solid #a1b1d2;
        box-shadow: 0 0 0 99999px rgba(10, 19, 38, 0.5);
        pointer-events: none;
      }
    }

    .app-preview-avatar {
      position: absolute;
      width: 256px;
      height: 256px;
      border-radius: 50%;

      #avatar-background-container,
      #avatar-frame-container {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      #avatar-sticker-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: -10px;
        left: -10px;

        .image-container {
          padding: 10px;
          padding-right: 14px;
          position: absolute;

          img {
            width: 100%;
          }

          .app-close-button {
            position: absolute;
            top: 0;
            right: -6px;
            display: none;
          }

          .app-sticker-border {
            position: absolute;
            margin: 8px;
            margin-right: 14px;
            width: calc(100% - 22px);
            height: calc(100% - 16px);
            top: 0;
            left: 0;
            display: none;
          }

          &[data-type='sticker'] {
            width: 80px;
          }

          &[data-type='upload'] {
            width: 256px;
          }

          &:hover {
            .app-sticker-border {
              display: block;
            }

            &:not([data-type='upload']) {
              .app-close-button {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.mobile-container {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }
}

.app-close-button {
  border-radius: var(--radius-radius-pill, 999px);
  background: var(--utility-red-500, #db2c2c);
  display: flex;
  width: 18px;
  height: 18px;
  padding: var(--spacing-spacing-4, 4px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.app-sticker-border {
  .app-sticker-border-line-top,
  .app-sticker-border-line-bottom,
  .app-sticker-border-line-left,
  .app-sticker-border-line-right {
    background: var(--divider-light, #dae1f5);
    width: 1px;
    height: 1px;
    position: absolute;
  }

  .app-sticker-corner-tl,
  .app-sticker-corner-tr,
  .app-sticker-corner-bl,
  .app-sticker-corner-br {
    width: var(--spacing-spacing-4, 4px);
    height: var(--spacing-spacing-4, 4px);
    border: 1px solid var(--divider-light, #dae1f5);
    background: var(--neutral-100, #fff);
    position: absolute;
  }

  .app-sticker-border-line-top {
    width: 100%;
    top: 2px;
  }

  .app-sticker-border-line-bottom {
    width: 100%;
    bottom: 2px;
  }

  .app-sticker-border-line-left {
    height: 100%;
    left: 2px;
  }

  .app-sticker-border-line-right {
    height: 100%;
    right: 2px;
  }

  .app-sticker-corner-tl {
    top: 0;
    left: 0;
  }

  .app-sticker-corner-tr {
    top: 0;
    right: 0;
  }

  .app-sticker-corner-bl {
    bottom: 0;
    left: 0;
  }

  .app-sticker-corner-br {
    bottom: 0;
    right: 0;
  }

  .app-sticker-rotate-handle-arm {
    background: var(--divider-light, #dae1f5);
    width: 2px;
    height: 12px;
    position: absolute;
    top: -10px;
    left: 50%;
    right: 50%;
  }

  .app-sticker-rotate-handle {
    width: 6px;
    height: 6px;
    border: 1px solid var(--divider-light, #dae1f5);
    background: var(--neutral-100, #fff);
    border-radius: 50%;
    position: absolute;
    top: -12px;
    left: calc(50% - 3px);
    right: calc(50% - 3px);
    cursor: grabbing;
  }
}

html {
  height: 100%;

  --min-height: max(100%, 980px);
}

body {
  padding: 0;
  margin: 0;
  min-height: var(--min-height);
  position: relative;

  @media (pointer: coarse) {
    min-height: 100dvh;
  }
}

button {
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: transparent;
}

h3 {
  color: var(--text-primary-darkbg, #f8f9ff);

  /* heading-md */
  font-family: 'Instrument Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 100% */

  margin: 0;
}

h6 {
  color: var(--text-supporting-darkbg, #8998b7);

  /* body-md */
  font-family: 'Instrument Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */

  margin: 0;
}

a {
  color: var(--text-primary-darkbg, #f8f9ff);
  text-decoration-line: underline;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  li {
    display: flex;
    width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-radius-8, 8px);
    border: 1px solid var(--primary-600, #3f4c68);
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: var(--radius-radius-7, 7px);
    }
  }
}
